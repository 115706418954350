// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
  import {} from "jquery-ujs";
  import _ from "lodash";
  import moment from "moment/moment";
  import "cocoon";
  import "trix"
  import "@rails/actiontext"

  require("turbolinks").start()
  require("@rails/activestorage").start()

  //require("channels")


  // Uncomment to copy all static images under ../images to the output folder and reference
  // them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
  // or the `imagePath` JavaScript helper below.
  //
  // const images = require.context('../images', true)
  // const imagePath = (name) => images(name, true)

  require("bootstrap")
  require("dm-file-uploader/src/js/jquery.dm-uploader")
  require("moment")
  require("moment-timezone")
  require("@fortawesome/fontawesome-pro")
  require("jquery-ui/ui/widgets/draggable")
  require("jquery-ui/ui/widgets/droppable")
  require("jquery-ui/ui/widgets/sortable")
  require("jquery-mask-plugin")
  require("jquery-caret-lm")
  require("select2")
  require("slick-carousel/slick/slick.min")
  require("@ttskch/select2-bootstrap4-theme/dist/select2-bootstrap4")
  require("stylesheets/application")

  window.jQuery = $
  window.$ = $
  window.Popper = Popper
  window.moment = moment
  window.Cookies = require('js-cookie/src/js.cookie')

  require("tempusdominus-bootstrap-4")
  require("trix_editor/custom_toolbar")