import Trix from "trix"

Trix.config.textAttributes.textFontSize = {
	styleProperty: "font-size",
  inheritable: true
 }

addEventListener("trix-initialize", function(event) { 
  var selectId = event.target.id+"_font_size"
  var fontSizeSelect = '<select name="Font Size" id="'+selectId+'"class="textFontSize" data-allow-clear="false"><option title="Extra Large" value="24">Extra Large</option><option title="Large" value="20">Large</option><option title="Normal" value="12">Normal</option><option title="Small" value="10">Small</option><option title="Extra Small" value="8">Extra Small</option></select>'
  var toolbarElem = event.target.toolbarElement
  var trix_element = toolbarElem.closest('trix-toolbar').nextElementSibling

  toolbarElem.querySelector(".trix-button-group--text-tools").insertAdjacentHTML("beforeend", fontSizeSelect)

  var textFontSizeElem = toolbarElem.querySelector(".textFontSize")
  textFontSizeElem.value = "12"

  const arrowKeysCodes = [37, 38, 39, 40]
  trix_element.addEventListener("keydown", function (e) {
    if(arrowKeysCodes.includes(e.which)) {
      syncFontSize()
    }    
  })
  trix_element.addEventListener("mouseup", function (e) {
    syncFontSize()
  })

  function syncFontSize() {
    if (pieceAtCursor().attributes.has("textFontSize")) {      
      textFontSizeElem.value = parseInt(pieceAtCursor().getAttribute("textFontSize"))
    }
     else {
      textFontSizeElem.value = 12
    }
  }

  function pieceAtCursor() {
    return trix_element.editor.getDocument().getPieceAtPosition(trix_element.editor.getPosition())
  }

  textFontSizeElem.addEventListener("change", function (event) {
    trix_element.editor.activateAttribute("textFontSize", event.target.value+"px");
    trix_element.focus();
  })
})


